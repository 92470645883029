<template>
  <div>
    <v-form v-model="isFormValid" ref="form" lazy-validation>
      <v-row dense>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.cp"
            label="Código postal"
            :rules="[$rules.required]"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.calle"
            label="Calle"
            :rules="[$rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.numeroExterior"
            :rules="[$rules.required]"
            label="Número exterior"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.numeroInterior"
            :rules="[$rules.required]"
            label="Número interior"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="form.colonia"
            :items="colonias"
            item-text="label"
            item-value="label"
            label="Colonia"
            :rules="[$rules.required]"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="form.municipio"
            :items="municipios"
            item-text="label"
            item-value="label"
            label="Municipio"
            :rules="[$rules.required]"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            readonly
            v-model="form.estado"
            :rules="[$rules.required]"
            label="Estado"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="form.rfc"
            label="RFC"
            :rules="[$rules.required]"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            small
            :disabled="!isFormValid"
            @click="sendForm"
          >
            <span>Siguiente</span>
          </v-btn>
        </v-col>
      </v-row></v-form
    >
  </div>
</template>

<script>
import { mainAxios } from "../../../mainAxios";

export default {
  props: {
    cp: {
      type: String,
    },
    rfc: {
      type: String,
    },
  },

  data() {
    return {
      form: {},
      colonias: [],
      municipios: [],
      estado: null,
      codPais: 10,
      isFormValid: false,
    };
  },

  watch: {
    cp() {
      this.form.cp = this.cp;
      this.getDireccionData();
    },

    rfc() {
      this.form.rfc = this.rfc;
    },
  },

  mounted() {
    this.form.cp = this.cp || "";
    this.form.rfc = this.rfc || "";
    if (this.cp) {
      this.getDireccionData();
    }
  },

  methods: {
    sendForm() {
      if (!this.$refs.form.validate()) return;
      const municipioObj = this.municipios.find(
        (municipio) => municipio.label === this.form.municipio
      );
      const coloniaObj = this.colonias.find(
        (colonia) => colonia.label === this.form.colonia
      );
      const emitData = {
        "direccion-usuario": {
          pais: "Mexico",
          codDpto: municipioObj.value.codDpto,
          codPais: this.codPais,
          municipio: municipioObj.label,
          codMunicipio: municipioObj.value.codMunicipio,
          colonia: coloniaObj.label,
          codColonia: coloniaObj.value.codColonia,
          estado: this.form.estado,
        },
        cp: this.form.cp,
        numeroInterior: this.form.numeroInterior,
        numeroExterior: this.form.numeroExterior,
        calle: this.form.calle,
        estado: parseInt(this.estado.codDpto),
        rfc: this.form.rfc,
      };
      console.log({ emitData });
      this.$emit("formChanged", emitData);
    },

    getDireccionData() {
      mainAxios
        .post(
          "v1/aig/direccion/landing",
          {
            codigoPostal: this.form.cp,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "agenteAccessToken"
              )}`,
            },
          }
        )
        .then((response) => {
          console.log({ response: response.data });
          this.colonias = response.data.data.catalogo.colonia.listado;
          this.municipios = response.data.data.catalogo.municipio.listado;
          this.estado = response.data.data.catalogo.estado.value;
          this.form.estado = this.estado.label;
          this.codPais = this.estado.codPais;
        });
    },
  },
};
</script>
